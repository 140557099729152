<template>
  <div id="energy-consumption" class="col-12">
    <v-row no-gutters id="energy-chart">
      <v-col cols="3" class="main-content usage-trend-controller">
        <div class="usage-trend-page session-ktc-inside usage-trend-menu-none open-menu" id="responsive_Court">
          <div class="row pt-1">
            <div class="col-12">
              <div class="text-center">
                <button
                  block
                  class="btn btn-yard btn-yard-height rounded-0"
                  :class="{
                    'btn-yard-active': !activeCourt,
                  }"
                  style="width: 90%; font-size: 20px;"
                  @click="!isTouchScreen ? changeActiveCourt() : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt() : resetScrolling() ) : ''"
                  @touchmove="touchMove"                
                >
                  <span>ALL COURTS</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">INDOOR SHOW COURT</label>
              <div class="button-container">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.indoor_show_group"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(item) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(item) : resetScrolling() ) : ''"
                  @touchmove="touchMove"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>

                <!-- Menu item virtual IS4 on Ipad -->
                <button
                    block
                    class="btn btn-yard rounded-0 mgl-8 virtual-menu-is4"
                    :style="{ width: width_btn_court + 'px' }"
                  >
                    <span>IS4</span>
                  </button>
                  <!-- Menu item virtual IS4 on Ipad -->
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">INDOOR PRACTICE COURT</label>
              <div class="button-container">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.indoor_practice_group"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(item) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(item) : resetScrolling() ) : ''"
                  @touchmove="touchMove"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">OUTDOOR TENNIS COURT</label>
              <div class="row m-0 button-container">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[0].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[0]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[0]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                  
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[0].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[1].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[1]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[1]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                   
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[1].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[2].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[2]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[2]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                   
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[2].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[3].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[3]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[3]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                   
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[3].name
                  }}</span>
                </button>
              </div>
              <div class="row m-0 mt-2 button-container">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[4].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[4]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[4]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                   
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[4].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[5].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[5]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[5]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                   
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[5].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[6].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[6]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[6]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                   
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[6].name
                  }}</span>
                </button>
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8"
                  :class="{
                    'btn-yard-active':
                      activeCourt &&
                      courts_data.outdoor_tennis_group[7].id ==
                        activeCourt.id,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(courts_data.outdoor_tennis_group[7]) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(courts_data.outdoor_tennis_group[7]) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                   
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{
                    courts_data.outdoor_tennis_group[7].name
                  }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">JUNIOR COURT</label>
              <div class="button-container">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.junior"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(item) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(item) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                  
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>

                <!-- Menu item virtual J3 and J4 on Ipad -->
                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8 virtual-menu-junior34"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>J3</span>
                </button>

                <button
                  block
                  class="btn btn-yard rounded-0 mgl-8 virtual-menu-junior34"
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>J4</span>
                </button>
                <!-- Menu item virtual J3 and J4 on Ipad -->
              </div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <label class="yard-title">ROOFTOP COURT</label>
              <div class="button-container">
                <button
                  block
                  class="btn btn-yard rounded-0"
                  v-for="(item, index) in courts_data.rooftop_group"
                  :key="index"
                  :class="{
                    'btn-yard-active':
                      activeCourt && item.id == activeCourt.id,
                    'mgl-8': index > 0,
                  }"
                  @click="!isTouchScreen ? changeActiveCourt(item) : '' "
                  @touchend=" isTouchScreen ?  ( (!blockTouch && !isScrolling ) ? changeActiveCourt(item) : resetScrolling() ) : ''"
                  @touchmove="touchMove"                  
                  :style="{ width: width_btn_court + 'px' }"
                >
                  <span>{{ item.name }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="9" class="main-content">
        <v-row no-gutters style="margin-bottom: 20px;">
          <v-col cols="12" >
            <span v-if="!activeCourt || !activeCourt.name" style="color: #0FC0FF; font-size: 24px;">
              All Courts
            </span>
            <span v-else style="color: #0FC0FF; font-size: 24px;">
              Court:
              <span style="color: #ffffff; font-size: 24px;">
                {{ activeCourt.name }}
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters id="group-select">
          <div class="text-left pt-2" style="max-width: 80px; font-weight: bold; font-size: 18px;padding-right:5px ;">PERIOD</div>
          <div class="wp-select-month">
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="monthYearText"
                  append-icon="fas fa-calendar-alt"
                  placeholder="Pick Month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="select-month"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="monthYear"
                :min="NOW_DATE().subtract(3, 'years').format(DATE_FORMAT_SCHEDULE)"
                :max="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                no-title
                type="month"
                @change="changeFilters"
                class="month-picker-table"
              >
              </v-date-picker>
            </v-menu>
            <!-- <div class="left-right-btn"> -->
              <button
                  class="btn btn-left-arrow"
                  :ripple="false"
                  @click="previousMonth()"
                  :disabled="disablePreviousMonth()"
                >
                <span class ="fas fa-caret-left" style="font-size: 22px; color: white; padding-top : 3px"></span>
              </button>
              <button
                  class="btn btn-right-arrow"
                  :ripple="false"
                  @click="nextMonth()"
                  :disabled="disableNextMonth()"
                >
                <span class ="fas fa-caret-right" style="font-size: 22px; color: white; padding-top: 3px;"></span>
              </button>
            <!-- </div> -->
        </div>
      </v-row>
        <v-row no-gutters id="group-chart">
          <v-col cols="12" class="main-chart">
            <template v-if="hasDataEnergyLogs">
              <div class="chart-title-top" style="text-align: center; font-size:16px">
                <b>Energy Consumption (kWh)</b>
              </div>
              <VueApexCharts
                type="bar"
                class="chart-horizontal-scroll"
                :options="chartOptions"
                :series="[
                  {
                    name: 'Energy Consumption (kWh)',
                    data: energyConsumptionData,
                  },
                ]"
                :width="width_bar"
                :height="height_bar"
              />
              <!-- <div class="current-month">
                <span>{{ monthYearText }}</span>
              </div> -->
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" src="../../assets/css/schedule.scss"></style>
<style lang="scss" src="../../assets/css/energy-consumption.scss"></style>
<script>
import VueApexCharts from "vue-apexcharts";
import { energyService, userProfileService } from "../../services";
import moment from "moment";
import courts_data_default from "@/components/courts_data";
export default {
  components: { VueApexCharts },
  data() {
    return {
      timeOutReload: null,
      monthYear: moment().format("YYYY-MM"),
      monthYearText: moment().format("MMM-YYYY"),
      courts_data: courts_data_default,
      courts: courts_data_default.data,
      groupModes: {},
      width_btn_court: 71,
      activeCourt: null,
      height_bar: 600,
      width_bar: "100%",
      chartOptions: {
        chart: {
          id: "line-chart",
          background: 'rgb(70,68,68,0.54)',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 5,
        },
        xaxis: {
          tickPlacement: "between",
          style: {
              fontSize: '14px'
          },
          crosshairs: {
            fill: {
              gradient: {
                opacityFrom: 0,
                opacityTo: 0,
              }
            }
          },
        },
        colors: ["#f79646"],
        
      },
      energyConsumptionData: [],
      dateData: [],
      dateColors: [],
      hasDataEnergyLogs: true,
      selectedCourtName: "All Courts",
      interval_browser: null,
      currentDateValues:[],
      cuurentEnergyConsumptionValues:[],
      isTouchScreen: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
      blockTouch:false,
      isScrolling:false,
    };
  },
  async created() {
    await this.getListCourts();
  },
  async mounted() {
    await this.reloadLineChart(0);
    this.getDimensionCourtsBar();
    this.getDimensionWidthBar();
    window.addEventListener("resize", this.getDimensionCourtsBar);
    window.addEventListener("resize", this.getDimensionWidthBar);
    this.interval_browser = setInterval(() => {
      userProfileService.getCurrentUserProfile();
      this.reloadLineChart(200,false);
    }, 5000);
  },
  destroyed() {
    clearInterval(this.interval_browser);
  },
  methods: {
    async getListCourts() {
      await this.$store.dispatch("courts/getCourts");
      this.courts = this.$store.state.courts.courts;
      this.courts_data = await this.handelGroupCourts(
        this.groupModes,
        this.courts
      );
    },
    toggleCourtMenu() {
      let collapse = document.getElementById("responsive_Court");
      let icon_rotate = document.getElementById("icon-caret-down");
      if(collapse && icon_rotate) {
        collapse.classList.toggle("open-menu");
        icon_rotate.classList.toggle("rotate-caret-down");
      }
    },
    handleChartClick(event, chartContext, config) {
      if(event.type != "touchend") {
        if (config.dataPointIndex !== -1) {
          chartContext.updateOptions({
            states: {
              active: {
                filter: {
                  type: "darken",
                  value: 0.8,
                },
              },
            },
          });
        } else {
          chartContext.updateOptions({
            states: {
              active: {
                filter: {
                  type: "none",
                },
              },
            },
          });
        }
      }
    },
    getDimensionCourtsBar() {
      let responsive_Court = document.getElementById("responsive_Court");
      if (responsive_Court && responsive_Court.offsetWidth) {
        let ratio_width = 360 / 71;
        this.width_btn_court = responsive_Court.offsetWidth / ratio_width;
        if (
          window.innerWidth < 1878 && window.innerWidth > 1000
        ) {
          this.width_btn_court = this.width_btn_court - 8;
        }
      }
      if (window.innerWidth == 3440 && window.innerHeight == 1440) {
        this.height_bar = 1000;
      } 

    },
    getDimensionWidthBar() {
      if (window.innerWidth <= 750) {
        this.width_bar = 750;
      }else{
        this.width_bar ="100%";
      }
    },
    async changeActiveCourt(court){
      this.blockTouch = true;
      this.isScrolling = false;
      if(court && court !== undefined){
        this.activeCourt = court;
        this.selectedCourtName = "COURT " + this.activeCourt.name;
      }else{
        this.activeCourt = null;
        this.selectedCourtName = "All Courts";
      }
      await this.changeFilters();
    },
    async changeFilters() {
      await this.reloadLineChart(200);
      this.blockTouch = false;
    },
    async reloadLineChart(time , firstLoad=true) {
      if (this.timeOutReload !== null) {
        clearTimeout(this.timeOutReload);
      }
      this.timeOutReload = setTimeout(async () => {
        this.monthYearText = moment(this.monthYear).format("MMM-YYYY");
        let data = {
          start_date: moment(this.monthYear)
            .startOf("month")
            .format(this.DATE_FORMAT_SCHEDULE),
          end_date: moment(this.monthYear)
            .endOf("month")
            .format(this.DATE_FORMAT_SCHEDULE),
          group_id: this.activeCourt && this.activeCourt.id ? this.activeCourt.id : "",
        };
        await energyService
          .getListEnergyLogs(data)
          .then((response) => {
            let dataEnergyLogs = response.data.data;
            if (dataEnergyLogs.length <= 0) {
              this.hasDataEnergyLogs = false;
            }
            const dateValues = dataEnergyLogs.map((item) => item.date);
            for (let i = 0; i < dateValues.length; i++) {
              this.dateColors.push("#FFFFFF");
            }
            const energyConsumptionValues = dataEnergyLogs.map(
              (item) => item.accumulative_energy
            );
            const identicalDate = dateValues.every((value, index) => value === this.currentDateValues[index]);
            const ideticalEnergy = energyConsumptionValues.every((value, index) => value === this.cuurentEnergyConsumptionValues[index]);
            if(firstLoad || !identicalDate || !ideticalEnergy){
              this.currentDateValues = dateValues;
              this.cuurentEnergyConsumptionValues = energyConsumptionValues;
              this.dateData = dateValues;
              this.energyConsumptionData = energyConsumptionValues;
              // TEST BAR CHART:
              //  this.energyConsumptionData = [
              //   20, 30, 50, 60, 10, 25, 11, 15, 35, 16, 34, 34, 43, 20, 30, 50, 60, 10, 70, 11, 15, 35, 16, 34, 34, 43, 16, 34, 34, 43, 93,
              // ];

              let numberData = energyConsumptionValues.map(Number);
              let maxData = Math.max.apply(null, numberData);
              let chartMax =  Math.ceil(maxData * Math.pow(10, 1)) / Math.pow(10, 1);
            

              let categoriesArr = this.dateData.map((dateString) => {
                let [day, , ] = dateString.split('-');
                return day;
              });
           
              this.chartOptions = {
                chart:{
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  categories: categoriesArr,
                  title: {
                    text: this.monthYearText,
                    style: {
                      color: "#FFFFFF",
                      fontSize: '16px',
                    },
                  },
                  labels: {
                    style: {
                      colors: this.dateColors,
                    },
                  },
                  crosshairs: {
                    fill: {
                      gradient: {
                        opacityFrom: 0,
                        opacityTo: 0,
                      }
                    }
                  },
                },
                yaxis:{
                  max: chartMax == 0 ? 1 : chartMax,
                  labels: {
                    style: {
                      colors: ["#FFFFFF"],
                      fontSize: '14px'
                    },
                  },
                },
                tooltip: {
                  style: {
                    fontSize: "18px",
                  },
                  theme: "dark",
                  x: {
                      show: true,
                      formatter: function(value, {dataPointIndex}) {
                        return moment(dateValues[dataPointIndex], 'D-M-YYYY').format('DD MMM');
                      }
                  },
                },
                colors: ["#f79646"],
                dataLabels:{
                  enabled: false
                },
                states: {
                  hover: {
                      filter: {
                          type: 'lighten',
                          value: 0.15,
                      }
                  },
                  active: {
                      allowMultipleDataPointsSelection: false,
                      filter: {
                          type: 'none',
                      }
                  },
                },
              };
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, time);
    },
    async previousMonth(){
      let minMonth = moment().subtract(this.MIN_MONTH_SELECT,'months');
      let curMonthYear = moment(this.monthYear,"YYYY-MM");
      let previousMonth = curMonthYear.add( -1 ,'months');
      if(previousMonth.isBefore(minMonth)){
        this.monthYear = minMonth.format("YYYY-MM");
      }else{
        this.monthYear = moment(previousMonth).format("YYYY-MM");
      }
      await this.reloadLineChart(200);
    },
    async nextMonth(){
      let nowMonthYear = moment();
      let curMonthYear = moment(this.monthYear,"YYYY-MM");
      let nextMonth = curMonthYear.add( 1 ,'months');
      if(nextMonth.isAfter(nowMonthYear)){
        this.monthYear = nowMonthYear.format("YYYY-MM") ;
      }else{
        this.monthYear = moment(nextMonth).format("YYYY-MM");
      }
      await this.reloadLineChart(200);
    },
    disablePreviousMonth(){
      let minMonth = moment().subtract(this.MIN_MONTH_SELECT,'months').format("YYYY-MM");
      return minMonth == this.monthYear ;
    },
    disableNextMonth(){
      let nowMonthYear = moment().format("YYYY-MM");
      return nowMonthYear == this.monthYear ;
    },
    touchMove() {
        this.isScrolling = true;
    },
    resetScrolling(){
      this.isScrolling = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.getDimensionCourtsBar);
    window.removeEventListener("resize", this.getDimensionWidthBar);
  },
};
</script>
